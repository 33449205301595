<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
         <b-col
          cols="12"
          md="9"
          class="mb-md-0 mb-2"
        >
          <label>No Register</label>
           <b-form-input
              id="no_register"
              autofocus
              trim
              placeholder="Pilih no register"
              :disabled="true"
              v-model="localData.no_register"
            />
        </b-col>
        <b-col
          cols="12"
          md="9"
          class="mb-md-0 mt-2"
        >
          <label>Tanggal</label>
            <b-form-input
              id="tanggal"
              autofocus
              trim
              :disabled="true"
              v-model="localData.tanggal"
            />
        </b-col>
         <b-col
          cols="12"
          md="9"
          class="mb-md-0 mt-2"
        >
          <label>Waktu</label>
            <b-form-input
              id="waktu"
              autofocus
              trim
              :disabled="true"
              v-model="localData.waktu"
            />
        </b-col>
         <b-col
          cols="12"
          md="9"
          class="mb-md-0 mt-2"
        >
          <label>Tempat</label>
            <b-form-input
              id="tempat"
              autofocus
              trim
              :disabled="true"
              v-model="localData.tempat"
            />
        </b-col>
         <b-col
          cols="12"
          md="9"
          class="mb-md-0 mt-2"
        >
          <label>Ketua Majelis</label>
            <b-form-input
              id="ketua_majelis"
              autofocus
              trim
              :disabled="true"
              v-model="localData.ketua_majelis"
            />
        </b-col>
         <b-col
          cols="12"
          md="9"
          class="mb-md-0 mt-2"
        >
          <label>Anggota Majelis</label>
            <b-form-textarea rows="3" v-model="localData.anggota" :disabled="true"></b-form-textarea>
        </b-col>
         <b-col
          cols="12"
          md="9"
          class="mb-md-0 mt-2"
        >
          <label>Tim Panel</label>
            <b-form-input
              id="tim_panel"
              autofocus
              trim
              :disabled="true"
              v-model="localData.tim_panel"
            />
        </b-col>
         <b-col
          cols="12"
          md="9"
          class="mb-md-0 mt-2"
        >
          <label>Tim Ahli</label>
            <b-form-input
              id="tim_ahli"
              autofocus
              trim
              :disabled="true"
              v-model="localData.tim_ahli"
            />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  uploadFileLogo,
} from '@/connection-api/instansi'
import {
  getAgamaById,
  updateAgama,
} from '@/connection-api/master'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
  },
  data() {
    return {
      error: {},
    }
  },
  directives: {
    Ripple,
  },
  props: {
    instansiId: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    showEditData: {},
    localData: {},
  },
  // async mounted() {
  //   this.loadData()
  // },
  methods: {
    async loadData() {
      const response = await getAgamaById(this.instansiId)
      if(response.data.success === true)
      {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Agama',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Data berhasil didapatkan!.',
          },
        })
        this.localData = response.data.data
      }
    },
    close() {
      this.$emit('emitCloseEditData')
    },
    async uploadLogo(id)
    {
      if(this.localData.file !== null)
      {
        const responseUpload = await uploadFileLogo(id, this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
      }
    },
    async updateData()
    {
      const response = await updateAgama(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Agama',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Agama',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
